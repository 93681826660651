import { template as template_eedd59f704ec4f5bb7f75a8a2245a84f } from "@ember/template-compiler";
const FKText = template_eedd59f704ec4f5bb7f75a8a2245a84f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_cf08cf83fd3e4df9809ec05880651002 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cf08cf83fd3e4df9809ec05880651002(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_51bf1d2dbc1042ed971c60f59ec922ac } from "@ember/template-compiler";
const FKLabel = template_51bf1d2dbc1042ed971c60f59ec922ac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
